import { h, resolveComponent } from 'vue'
import {
	createRouter,
	createWebHashHistory,
	createWebHistory,
} from 'vue-router'
import store from '@/store/index'

import DefaultLayout from '@/layouts/DefaultLayout'

const userAuth = () => (to, from, next) => {
	if (!store.state.token) {
		return next()
	}

	next('/manage-ad')
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: DefaultLayout,
		redirect: '/login',
		children: [
			// {
			//     "path" : "/dashboard",
			//     "name" : "Dashboard",
			//     "component" : ()=>import("@/views/Dashboard.vue"),
			// },
			{
				path: '/account',
				name: 'Account',
				children: [
					{
						path: '/account/seller',
						name: 'SellerAccount',
						component: () =>
							import('@/views/pages/member/SellerAcountInfo.vue'),
					},
					{
						path: '/account/seller/detail/:id',
						name: 'SellerAccountDetail',
						component: () =>
							import('@/views/pages/member/SellerAccountDetail.vue'),
					},
					{
						path: '/account/importer',
						name: 'ImporterAccount',
						component: () =>
							import('@/views/pages/member/ImporterAccountInfo.vue'),
					},
					{
						path: '/account/importer/detail/:id',
						name: 'ImporterAccountDetail',
						component: () => import('@/views/pages/member/ImporterAccountDetail.vue'),
					},
					{
						"path": "/account/importer/detail/:id/channel-management",
						"name": "ChannelManagement",
						component: () => import("@/views/pages/member/ChannelManagement.vue"),
					},
					{
						path: '/account/admin',
						name: 'AdminAccount',
						component: () => import('@/views/pages/member/AdminAcountInfo.vue'),
					},
					{
						path: '/account/findbank',
						name: 'FindBankAccount',
						component: () => import('@/views/pages/member/FindBankAccount.vue'),
					},
				],
			},
			{
				path: '/manage-ad',
				name: 'Advertise',
				component: () => import('@/views/pages/ManageAd.vue'),
			},
			{
				path: '/manage-chat',
				name: 'Chat',
				component: () => import('@/views/pages/ManageChat.vue'),
			},
			{
				path: '/manage-event',
				name: 'Event',
				component: () => import('@/views/pages/ManageEvent.vue'),
			},
			{
				path: '/manage-theme',
				name: 'Theme',
				component: () => import('@/views/pages/ManageTheme.vue'),
			},
			{
				path: '/manage-order',
				name: 'Order',
				component: () => import('@/views/pages/ManageOrder.vue'),
			},
			{
				path: '/manage-popup',
				name: 'Popup',
				component: () => import('@/views/pages/ManagePopup.vue'),
			},
			{
				path: '/manage-order/detail/:id',
				name: 'ManageOrderDetail',
				component: () => import('@/views/pages/ManageOrderDetail.vue'),
			},
			{
				path: '/manage-point',
				name: 'Point',
				component: () => import('@/views/pages/ManagePoint.vue'),
			},
			{
				path: '/manage-settlement',
				name: 'SellerSettlement',
				component: () => import('@/views/pages/ManageSellerSettlement.vue'),
			},
			{
				path: '/manage-settlement-am002',
				name: 'SellerSettlementAM002',
				component: () => import('@/views/pages/ManageSellerSettlementAM002.vue'),
			},
			{
				path: '/manage-goods',
				name: 'Goods',
				component: () => import('@/views/pages/ManageGoods.vue'),
			},
			{
				path: '/manage-goods/:id',
				name: 'GoodsDetail',
				component: () => import('@/views/pages/ManageGoodsDetail.vue'),
			},
			{
				path: '/manage-add-goods',
				name: 'AddGoods',
				component: () => import('@/views/pages/ManageAddGoods.vue'),
			},
			{
				path: '/manage-notice',
				name: 'Notice',
				component: () => import('@/views/pages/ManageNotice.vue'),
			},
			{
				path: '/manage-importer',
				name: 'Importer',
				component: () => import('@/views/pages/ManageImporterApprove.vue'),
			},
			{
				path: '/manage-importer/:id',
				name: 'Importer Detail',
				component: () =>
					import('@/views/pages/ManageImporterApproveDetail.vue'),
			},
			{
				"path": "/manage-supply-price",
				"name": "공급가 변경 승인 관리",
				component: ()=>import("@/views/pages/ManageSupplyPrice.vue"),
			},
			{
				"path": "/manage-good-top",
				"name": "ManageGoodTop",
				component: ()=>import("@/views/pages/ManageGoodTop.vue"),
			},
			{
				"path": "/manage-seller-top",
				"name": "ManageSellerTop",
				component: ()=>import("@/views/pages/ManageSellerTop.vue"),
			},
			// {
			//     path: '/login',
			//     name: 'Login',
			//     component: () => import('@/views/pages/Login.vue'),
			// },
			{
				path: '/ranking',
				name: 'Ranking',
				children: [
					{
						path: '/ranking/seller',
						name: 'SellerRanking',
						component: () => import('@/views/pages/ranking/SellerRanking.vue'),
					},
					{
						path: '/ranking/importer',
						name: 'ImporterRanking',
						component: () =>
							import('@/views/pages/ranking/ImporterRanking.vue'),
					},
					{
						path: '/ranking/wine',
						name: 'WineRanking',
						component: () => import('@/views/pages/ranking/WineRanking.vue'),
					},
				],
			},
			{
				path: '/logs',
				name: 'Logs',
				children: [
					{
						path: '/logs/common-sms-histories',
						name: 'CommonSmsHistories',
						component: () =>
							import('@/views/pages/logs/CommonSmsHistories.vue'),
					},
				],
			},
			{
				path: '/system',
				name: 'System',
				children: [
					{
						path: '/system/card_interest_free',
						name: 'CardInterestFree',
						component: () =>
							import('@/views/pages/system/CardInterestFree.vue'),
					},
					{
						path: '/system/notification',
						name: 'SystemNotification',
						component: () =>
							import('@/views/pages/system/SystemNotification.vue'),
					},
				],
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/pages/Login.vue'),
		beforeEnter: userAuth(),
	},
]

const router = createRouter({
	// "history" : createWebHashHistory( process.env.BASE_URL ),
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
})

export default router
